.EPiServerForms {
    .Form__Description {
      margin-bottom: 1rem;
    }
  
    .FormImageSubmitButton {
      width: 100%;
      height: 100%;
      padding: 0;
      border: 0;
      background: none;
      box-shadow: none;
    }
  
    .Form__Status {
      .Form__Status__Message {
        display: block;
        padding: 1rem;
        margin: 1rem 0;
      }
  
      .Form__Success__Message {
        background-color: #84c8e4;
        color: #333;
      }
  
      .Form__Warning__Message {
        background-color: #fff8aa;
        color: red;
      }
  
      .Form__Readonly__Message {
        display: block;
        padding: 1rem;
        margin: 1rem 0;
        background-color: #fff8aa;
        color: red;
      }
    }
  
    .Form__NavigationBar {
      .Form__NavigationBar__Action {
        display: inline-block;
        padding: inherit;
        vertical-align: middle;
        text-align: center;
        border: 1px solid rgba(41, 128, 189, 0.5);
        border-radius: 4px;
        font-size: inherit;
        height: 2em;
        width: 10em;
        max-width: 10rem;
        color: initial;
        background-image: linear-gradient(to top, #fff, #e6e6e6);
        background-image: -moz-linear-gradient(top, #fff, #e6e6e6);
        background-image: -ms-linear-gradient(top, #fff, #e6e6e6);
        background-image: -webkit-linear-gradient(top, #fff, #e6e6e6);
  
        &:disabled {
          cursor: not-allowed;
          opacity: 0.4;
        }
      }
  
      .Form__NavigationBar__ProgressBar {
        display: inline-block;
        border: 1px solid rgba(41, 128, 189, 0.5);
        vertical-align: middle;
        width: 40%;
  
        .Form__NavigationBar__ProgressBar--Progress {
          background-color: rgba(41, 128, 189, 0.5);
          width: 0%;
          height: 1rem;
        }
  
        .Form__NavigationBar__ProgressBar--Text {
          display: none;
        }
      }
    }
  
    .FormStep .FormStep__Description {
      margin-bottom: 1rem;
    }
  
    .Form__Element {
      margin: 0 0 1.5rem 0;
  
      .Form__Element__Caption {
        display: inline-block;
        min-width: 15%;
        vertical-align: top;
      }
  
      .Form__Element__ValidationError {
        color: red;
        display: block;
        word-wrap: break-word;
      }
    }
  
    .hide {
      display: none;
    }
  
    .visually-hidden {
      position: absolute !important;
      height: 1px;
      width: 1px;
      overflow: hidden;
      clip: rect(1px 1px 1px 1px);
      clip: rect(1px, 1px, 1px, 1px);
      white-space: nowrap;
    }
  
    .FormSubmitButton:disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }
  
    .FormCaptcha {
      .FormTextbox__Input {
        width: 8rem;
      }
  
      .FormCaptcha__Refresh {
        font-size: 1em;
        width: auto;
        height: auto;
      }
    }
  
    .FormChoice--Image {
      .FormChoice--Image__Item {
        display: inline-block;
        max-width: 40%;
        text-align: center;
        margin: 0.5rem;
      }
  
      .FormChoice__Input:checked ~ img {
        border: 1px solid rgba(41, 128, 189, 0.5);
      }
    }
  
    .FormRange .FormRange__Input {
      max-width: 60%;
      display: inline;
    }
  
    .EditView__InvisibleElement {
      &.FormStep {
        display: block;
        border-radius: 4px;
        border-color: rgba(184, 192, 197, 0.6);
        background-color: rgba(184, 192, 197, 0.6);
        color: #000;
        padding: .2rem;
        font-family: Verdana;
        box-shadow: 3px 3px 5px #ccc;
  
        .FormStep__Warning {
          color: red;
          background-color: #fff8aa;
          display: block;
          word-wrap: break-word;
        }
      }
  
      &.FormHidden {
        display: inline-block;
        border-radius: 4px;
        border: 1.5px outset rgba(184, 192, 197, 0.6);
        padding: .2rem 1rem;
        min-width: 18rem;
      }
    }
  
    .Warning, .Form__Warning {
      color: red;
      background-color: #fff8aa;
      display: block;
      word-wrap: break-word;
    }
  }
  